import "./RecentTripsStyles.css";
import React from "react";
import RecentTripsData from "./RecentTripsData";
import { destinations } from "../../Booking/Info";

const RecentTrips = () => {
    return (
        <div className="trip">
            <h1>Our Packages</h1>
            <p>Experience the adventure with our travel packages!</p>

            <div className="trip-card">
                {destinations.map((destination, index) => (
                    <RecentTripsData
                        key={index}
                        tImg={require(`../../../assets/${destination.country.toLowerCase()}.jpg`)}
                        heading={destination.name}
                        text={`From $${destination.cost}`}
                        url={{
                            pathname: `/booking/${destination.id}`,
                            search: `?date=${destination.tomorrowDate}`
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default RecentTrips;

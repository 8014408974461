import "./NavbarStyles.css";
import React from "react";
import { useState } from "react";
import { MenuItems } from "./MenuItems";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  let [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked((clicked = !clicked));
    };

    return (<nav className="navbarItems">
        <h1 className="navbar-logo">
            <Link to="/" className="link-unstyled">
                <FontAwesomeIcon icon={faPlane}/> PROTECT AIRLINE
            </Link>
        </h1>

        <div className="menu-icons" onClick={handleClick}>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
            </div>

            <ul className={clicked ? "nav-menu active" : "nav-menu"}>
                {MenuItems.map((item) => {
                    return (<li key={item.title}>
                        <Link className={item.className} to={item.url}>
                            <i className={item.icon}></i>
                            {item.title}
                        </Link>
                    </li>);
                })}
            </ul>
    </nav>);
};

export default Navbar;

import { atom } from 'recoil';

export const firstNameState = atom({
    key: `firstNameState`,
    default: ""
})

export const lastNameState = atom({
    key: `lastNameState`,
    default: ""
})

export const emailState = atom({
    key: `emailState`,
    default: ""
})

export const protectionState = atom({
    key: 'protectionState',
    default:{
        protectionSelected: false,
        bookingCost: 0,
        totalValue: 0,
        protectionValue: 0,
        eventDate: new Date()
    }
})
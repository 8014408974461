import React from "react";
import {useRecoilState} from "recoil";
import { firstNameState, lastNameState, emailState } from "../../events/recoils";

const PersonalAndCreditInfo = ({ formatExpirationDate, formatCardNumber }) => {

    const [firstName, setFirstName] = useRecoilState(firstNameState);
    const [lastName, setLastName] = useRecoilState(lastNameState);
    const [email, setEmail] = useRecoilState(emailState);

    const handleChange = (key) => (event) => {
        switch (key) {
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <h3>Personal Information</h3>
            <div className="personal-info-box">
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="First Name"
                        className="input"
                        onChange={handleChange("firstName")}
                        value={firstName}
                    />
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        placeholder="Last Name"
                        className="input"
                        onChange={handleChange("lastName")}
                        value={lastName}
                    />
                </div>
                <div className="input-group">
                    <input
                        type="email"
                        placeholder="Email"
                        className="input"
                        onChange={handleChange("email")}
                        value={email}
                    />
                </div>
            </div>

            <h3>Credit Card Information</h3>
            <div className="credit-card-info-box">
                <div className="input-group">
                    <label>Cardholder Name:</label>
                    <input
                        type="text"
                        className="input"
                    />
                </div>
                <div className="input-group">
                    <label>Card Number:</label>
                    <input
                        className="input"
                        maxLength="19"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        type="text"
                        onChange={(e) => {
                            e.target.value = formatCardNumber(e.target.value);
                        }}
                    />
                </div>
                <div className="input-group">
                    <label>Expiration Date:</label>
                    <input
                        className="input"
                        maxLength="7"
                        placeholder="MM/YYYY"
                        type="text"
                        onChange={(e) => {
                            e.target.value = formatExpirationDate(e.target.value);
                        }}
                    />
                </div>
                <div className="input-group">
                    <label>CVV:</label>
                    <input
                        type="text"
                        className="input"
                        placeholder="XXX"
                        maxLength="3"
                    />
                </div>
            </div>
        </>
    );
};

export default PersonalAndCreditInfo;
import "./AboutStyles.css";
import React from "react";

const AboutUs = () => {
  return (
    <div className="about">
      <h2>Protect Group</h2>
      <p>
          Protect is a global leader in the design, implementation and administration of life assistance services.
          We create sector specific programs that support businesses in improving the experience of their end users.
          Where sectors have a need for complimentary services to their core proposition, we partner to provide them in
          their name as the appointed administrator.
          Our involvement delivers real commercial advantage through membership programs which benefit their own
          customers or users.
      </p>
      <p>
          Our programs all share a number of characteristics, they must: enhance the user experience, provide user
          confidence, be commercially beneficial to the member and their users, be digitally distributed and have
          global potential.
          An innovative end to end digital platform that integrates seamlessly through APIs into members’ own platforms,
          enables us to deliver an enhanced user experience in their name as their administrator.
          It is the practice of Protect that we have our own insurance policies through a panel of reputable
          insurance providers. We operate programs in the Event, Travel, Participation, Sport, Leisure,
          Accommodation and Transportation sectors.
      </p>
    </div>
  );
};

export default AboutUs;

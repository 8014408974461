export const MenuItems = [
  {
    title: "Home",
    url: "/",
    className: "nav-links",
    icon: "fa-solid fa-house-user",
  },
  {
    title: "Packages",
    url: "/packages",
    className: "nav-links",
    icon: "fa-solid fa-box",
  },
  {
    title: "About",
    url: "/about",
    className: "nav-links",
    icon: "fa-solid fa-circle-info",
  },
];

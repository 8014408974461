import React from "react";
import Hero from "../components/Main/Presentation/SearchFlight";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import RecentTrips from "../components/Main/OurPackages/RecentTrips";
import img from "../assets/9.jpg";

const Packages = () => {
  return (
      <>
          <Navbar/>
          <Hero
              cName="hero-mid"
              heroImg={img}
              title="Packages"
          />
          <RecentTrips/>
          <Footer/>
      </>
  );
};

export default Packages;
import React from "react";
import Recommendation from "../components/Main/Recommendation/Recommendation";
import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import RecentTrips from "../components/Main/OurPackages/RecentTrips";
import SearchFlight from "../components/Main/Presentation/SearchFlight";

const Home = () => {
    return (
        <>
            <Navbar/>
            <SearchFlight
                cName="hero"
                heroImg="https://images.pexels.com/photos/1089306/pexels-photo-1089306.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                title="Your journey Your story"
                text="Choose your favorite destination:"
                btnText="Search"
                url="#"
                btnClass="show"
            />
            <RecentTrips/>
            <Recommendation/>
            <Footer/>
        </>
    );
};

export default Home;
import "./DestinationStyles.css";
import img3 from "../../../assets/3.jpg";
import img4 from "../../../assets/new-york.jpg";
import React from "react";
import DestinationData from "./RecommendationData";

const Recommendation = () => {
    return (
        <div className="destination">
            <h1>Fly With Us!</h1>
            <p>Discover the world. Live unforgettable adventures at every destination!</p>
            <DestinationData
                cName="first-des revese"
                heading="Lorem ipsum dolor sit amet"
                desciption="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
         et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
          ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
           nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
            anim id est laborum"
                img1={img3}
                img2={img4}
            />
        </div>
    );
};

export default Recommendation;

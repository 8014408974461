import "./FooterStyles.css";
import React from "react";
import moment from "moment";

const Footer = () => {

  const currentYear = moment().format("YYYY");

  return (
      <div className="footer">

        <div className="top">

          <div>
            <h1>PROTECT AIRLINE</h1>
            <p>Choose your favourite destination.</p>
          </div>

          <div>
            <a href="#">
              <i className="fa-brands fa-facebook-square"></i>
            </a>
            <a href="#">
              <i className="fa-brands fa-instagram-square"></i>
            </a>
            <a href="#">
              <i className="fa-brands fa-youtube-square"></i>
            </a>
            <a href="#">
              <i className="fa-brands fa-whatsapp-square"></i>
            </a>
          </div>

        </div>

        <div className="bottom">

          <div>
            <h4>Info</h4>
            <a href="#">Status</a>
            <a href="#">Licence</a>
          </div>

          <div>
            <h4>Help</h4>
            <a href="#">Support</a>
            <a href="#">Troubleshooting</a>
            <a href="#">Contact Us</a>
          </div>

          <div>
            <h4>Others</h4>
            <a href="#">Terms of Service</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Licence</a>
          </div>

        </div>

        <div className="copyright">
          <p>&copy; {currentYear} AIRLINE DEMO SITE by Protect Group.</p>
        </div>

      </div>
  );
};

export default Footer;

import React from "react";
import Hero from "../components/Main/Presentation/SearchFlight";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import img1 from "../assets/12.jpg";
import Aboutus from "../components/About/Aboutus";

const About = () => {
    return (
            <div className="aboutPage">
                <Navbar/>
                <Hero cName="hero-mid" heroImg={img1} title="About Us"/>
                <Aboutus/>
                <Footer/>
            </div>
    );
};

export default About;
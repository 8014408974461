import React, { useState } from "react";
import "./SearchFlight.css";
import { Link } from "react-router-dom";
import { destinations } from "../../Booking/Info";

const SearchFlight = ({ cName, heroImg, title, text }) => {
    const [selectedDestination, setSelectedDestination] = useState(destinations[0].id);
    const [selectedDate, setSelectedDate] = useState("");
    const [numPassengers, setNumPassengers] = useState(1);

    const handleDestinationChange = (e) => {
        setSelectedDestination(parseInt(e.target.value));

    };

    const handlePassengerChange = (e) => {
        const passengers = parseInt(e.target.value);
        setNumPassengers(passengers);
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

  return (
      <div className={cName}>
        <img alt="Heroimg" src={heroImg} />
        <div className="hero-text">
          <h1>{title}</h1>
          <p>{text}</p>
            <div className="search-form">
                <input type="text" placeholder="Origin" className="search-input isEnabled"/>
                <select className="search-select isEnabled" onChange={handleDestinationChange}>
                    {destinations.map(destination => (
                        <option key={destination.id} value={destination.id}>{destination.country}</option>
                    ))}
                </select>
                <input type="date"
                       placeholder="YYYY-MM-DD"
                       value={selectedDate}
                       onChange={handleDateChange}
                       className="search-input isEnabled"
                />
                <input type="number" placeholder="Passengers" value={numPassengers} onChange={handlePassengerChange}
                       className="search-input isEnabled"/>
                <Link className="link-unstyled" to={`/booking/${selectedDestination}?passengers=${numPassengers}&date=${selectedDate}`}>
                    <a href="/" className="show isEnabled">Search</a>
                </Link>
            </div>
        </div>
      </div>
  );
};

export default SearchFlight;
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { RefundableOptions, RefundableProvider } from 'protect-group-dynamic-refundable-react'

const options: RefundableOptions = {
    environment: 'test',
    currencyCode: 'USD',
    languageCode: 'en',
    vendorCode: 'ven_local_28f1fbae43a74abbaf5bbce6bc51bbf0',
    eventDateFormat: 'DD/MM/YYYY', // optional
    containerSize: 'medium', //optional
    useSaleAction: true
    // optional, set this to false if you intend to call our sale endpoint manually
}

ReactDOM.render(
    <RefundableProvider options={options}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </RefundableProvider>,
    document.getElementById("root")
);
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import React from "react";
import img1 from "../assets/left.jpg";
import Hero from "../components/Main/Presentation/SearchFlight";
import "../components/Booking/BookFlight.css";
import ConfirmBooking from "../components/Booking/ConfirmBooking";
import { useParams } from "react-router-dom";
import {destinations} from "../components/Booking/Info";

const BookFlight = () => {
    const { packageID } = useParams();
    const selectedDestination = destinations.find(destination => destination.id === parseInt(packageID));
    const { name: packageName, cost: packageCost } = selectedDestination;

    return (
            <div className="bookFligthPage">
                <Navbar/>
                <Hero cName="hero-mid book-flight" heroImg={img1} title="Book to fly!" text="Few more steps..."/>
                <ConfirmBooking packageName={packageName} packageCost={packageCost}/>
                <Footer/>
            </div>
    );
};

export default BookFlight;
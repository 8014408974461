import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/Main/Presentation/SearchFlight";
import Footer from "../components/Footer/Footer";
import React from "react";
import "../components/Main/Presentation/SearchFlight.css";
import BookingConfirmationEmail from "../widget/bookingConfirmationEmail";
import { useRecoilValue } from 'recoil';
import { protectionState } from '../events/recoils';
import { useLocation } from "react-router-dom";

const Thanks = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookingReference = searchParams.get("bookingRef");
    const isRefundable = useRecoilValue(protectionState).protectionSelected

    return (
        <div className="thanks-page">
            <Navbar/>
            <Hero
                cName="hero-mid"
                heroImg="https://images.unsplash.com/photo-1454942901704-3c44c11b2ad1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                title="THANKS FOR YOUR PURCHASE!"
                text={isRefundable && "YOUR PURCHASE IS PROTECTED"}
            />
            {isRefundable && <BookingConfirmationEmail bookingReference={bookingReference} />}
            <Footer/>
        </div>
    );
};

export default Thanks;
import { useLocation } from "react-router-dom";
import { destinations } from "../components/Booking/Info";
import { useRecoilState } from "recoil";
import { emailState, firstNameState, lastNameState } from "../events/recoils";
import { useRefundableActions } from "protect-group-dynamic-refundable-react";

export default function useConfirmBooking() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const packageID = parseInt(location.pathname.split("/")[2]);
    const numPassengers = parseInt(searchParams.get("passengers")) || 1;
    const selectedDate = searchParams.get("date") || new Date().toISOString().split('T')[0];
    const destination = destinations.find(item => item.id === packageID);
    const [firstName, setFirstName] = useRecoilState(firstNameState);
    const [lastName, setLastName] = useRecoilState(lastNameState);
    const [email, setEmail] = useRecoilState(emailState);
    const { writeSale } = useRefundableActions();

    const isValidForm = () => {
        return firstName.trim() !== "" && lastName.trim() !== "" && email.trim() !== "";
    }

    const formatCardNumber = (input) => {
        return input.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
    };

    const formatExpirationDate = (input) => {
        return input.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{2})\/(\d{4}).*/, '$1/$2');
    };

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const generateUniqueReference = () => {
        const randomNumber = Math.floor(Math.random() * 900000) + 100000;
        return `BOOK-${randomNumber}`;
    };

    const theBookingRef = generateUniqueReference()

    const handleMakePayment = async () => {
        try {
            const request = {
                bookingReference:  theBookingRef,
                customers: [
                    { firstName: firstName, lastName: lastName, email: email }
                ]
            };

            const response = await writeSale(request);

            console.log("Sale response:", response);

        } catch (error) {
            console.error("Error during payment:", error);
        }
    };

    return {
        destinations,
        handleMakePayment,
        formatCardNumber,
        formatExpirationDate,
        handleFirstNameChange,
        handleLastNameChange,
        handleEmailChange,
        isValidForm,
        selectedDate,
        numPassengers,
        destination,
        theBookingRef,
    }
}
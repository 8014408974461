import React from "react";
import { Link } from "react-router-dom";
import "./RecentTripsStyles.css";

const RecentTripsData = ({ tImg, heading, text, url }) => {
    return (
        <div className="t-card">
            <div className="t-img">
                <img alt="img" src={tImg}/>
            </div>
            <h4>{heading}</h4>
            <p>{text}</p>
            <Link to={url}>
                <button className="book-button">Book Now!</button>
            </Link>
        </div>
    );
};

export default RecentTripsData;
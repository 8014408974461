import React from 'react';
import { Link } from "react-router-dom";
import PersonalAndCreditInfo from "./PersonalAndCreditInfo";
import useConfirmBooking from "../../hooks/useConfirmBooking";
import { RefundableContent } from 'protect-group-dynamic-refundable-react'
import "../../components/Booking/BookFlight.css";
import useUpdateQuoteData from "../../hooks/useUpdateQuoteData";

const ConfirmBooking = () => {
    const {
        handleMakePayment,
        formatCardNumber,
        formatExpirationDate,
        isValidForm,
        destination,
        numPassengers,
        theBookingRef,
        selectedDate
    } = useConfirmBooking()

    const formValid = isValidForm()
    const {refundState: { totalValue, protectionValue, bookingCost, protectionSelected },
        handleProtectionChange} = useUpdateQuoteData(destination.cost, numPassengers, new Date(selectedDate))

    return (
        <div className="container">
            <h2>Booking Info</h2>

            <div className="boxing-container">
                <h3>Pricing</h3>
                <div className="pricing-box">
                    <div className="pricing-item">
                        <div>
                            <h4>Element</h4>
                            <p>{destination ? destination.name : "Unknown"} (x{numPassengers})</p>
                            <p>{protectionSelected && "Protection Price"}</p>
                        </div>
                        <div>
                            <h4>Cost</h4>
                            <p>{bookingCost} USD</p>
                            <p>{protectionSelected && protectionValue}{protectionSelected && " USD"}</p>
                        </div>
                    </div>
                </div>

                <div className="price">
                    <div className="element-pricing totalPrice">
                        <h4>Total price</h4>
                        <p>{totalValue} USD</p>
                    </div>
                </div>

                <RefundableContent onProtectionChange={handleProtectionChange}/>

                <PersonalAndCreditInfo
                    formatCardNumber={formatCardNumber}
                    formatExpirationDate={formatExpirationDate}
                />

                <Link to={{pathname: "/thanks", search: `?bookingRef=${theBookingRef}`}}>
                    <button
                        className="book-button"
                        onClick={handleMakePayment}
                        disabled={!formValid}>{!formValid ? 'Fields missing' : 'PAY NOW'}
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default ConfirmBooking;
import { useRefundableActions } from "protect-group-dynamic-refundable-react";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { protectionState } from "../events/recoils";

export default function useUpdateQuoteData(bookingCostPerPassenger, numberOfTickets, eventDate) {
    const { updateQuoteData } = useRefundableActions();
    const [refundState, setRefundState] = useRecoilState(protectionState);

    const handleProtectionChange = (args) => {
        const { protectionSelected, protectionValue, totalValue } = args
        const bookingCost = bookingCostPerPassenger * numberOfTickets;
        setRefundState(prev => ({
            ...prev,
            protectionSelected,
            totalValue: totalValue * numberOfTickets,
            bookingCost,
            protectionValue,
            eventDate
        }))
    };

    useEffect(() => {
        updateQuoteData({ totalValue: bookingCostPerPassenger, numberOfTickets, eventTravelDateTime: eventDate });
    }, [bookingCostPerPassenger, numberOfTickets, eventDate, updateQuoteData]);

    return {
        refundState,
        handleProtectionChange
    }
}
import React from "react";

const BookingConfirmationEmail = ({ bookingReference }) => {

    return (
        <>
            <div id="DIV_1">
                <div id="DIV_2">
                    <span id="SPAN_3"><strong id="STRONG_4">Refundable Booking</strong></span>
                    <h6 id="H6_5">
                        To make changes to your booking or where it is cancelled or changed by the provider, please contact
                        our Customer Service Team.
                    </h6>
                    <h6 id="H6_8">
                        This is a refundable booking. If you are unable to attend your booking due to unforeseen circumstances
                        and can provide evidence as listed in the
                        <strong id="STRONG_9">Terms &amp; Conditions</strong> <a href="https://www.refundable.me/extended/en/"
                                                                                 id="A_10"><i id="I_11">here</i></a>,
                        you may be entitled to a full refund.
                    </h6>
                    <h6 id="H6_12">
                        Your booking reference number is <i id="I_13">{bookingReference}</i>.
                        Apply for a refund using the form
                        <a href={`https://test.form.refundable.me/forms/refund?bookingReference=${bookingReference}&memberId=985`}
                           id="A_14"><i id="I_15">
                            here.
                        </i></a>
                    </h6>
                </div>
            </div>
        </>
    );
};

export default BookingConfirmationEmail;
const moment = require("moment");
const date = moment().add(10, "day").format("YYYY-MM-DD");

const destinations = [
    {
        id: 1,
        country: "GREECE",
        name: "Athens - Greece",
        cost: 400,
        tomorrowDate: date
    },
    {
        id: 2,
        country: "JAPAN",
        name: "Kyoto - Japan",
        cost: 1200,
        tomorrowDate: date
    },
    {
        id: 3,
        country: "PERU",
        name: "Machu Pichu - Peru",
        cost: 600,
        tomorrowDate: date
    },
    {
        id: 4,
        country: "URUGUAY",
        name: "Punta Del Este - Uruguay",
        cost: 800,
        tomorrowDate: date
    }
]

export { destinations };
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Service from "./routes/Packages";
import BookFlight from "./routes/BookFlight";
import Thanks from "./routes/Thanks";
import {destinations} from "./components/Booking/Info";
import {RecoilRoot} from "recoil";

function App() {
    return (
        <RecoilRoot>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/packages" element={<Service/>}/>
                    <Route path="/booking/:packageID" element={<BookFlight destinations={destinations}/>}/>
                    <Route path="/thanks" element={<Thanks/>}/>
                </Routes>
            </div>
        </RecoilRoot>
    );
}

export default App;